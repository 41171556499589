import {
  selectBoxes,
  selectFilteredResults,
  selectLoading,
  selectPageType,
} from "../../Redux/slices/azelis/azelisSlice";
import {
  goBack,
  selectPage,
  showResults,
} from "../../Redux/slices/navigationSlice";
import { numberWithCommas } from "../../utils/function";
import useLoadSection from "../../utils/hooks/useLoadSection";
import Card from "../card/card";
import CardImage from "../cardimage/cardimage";
import LittleFilter from "./filter/filter";
import SideFilter from "./SideFilter/ParentItems";
import TrendsFilter from "./TrendsFilter";
// import { Button } from "react";
import { Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";

import "./BoxResults.css";
import { useMemo } from "react";

export default function BoxResults() {
  const dispatch = useDispatch();
  const section = useSelector(selectPage);
  const filterBoxes = useSelector(selectBoxes);
  const results = useSelector(selectFilteredResults);
  const loadState = useSelector(selectLoading);
  const pageType = useSelector(selectPageType);

  const useImageCard = section !== "product" && section !== "formulations";

  useLoadSection();

  const moveTopSearch = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const filteredResults = useMemo(()=> {
    if (results && results.length && section === "product") {
      return results.sort((a, b)=> a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1: -1)
    } else {
      return results
    }
  }, [results, section])



  return (
    <div className="box-results-main">
      <div className="d-flex align-items-center justify-content-between btn-area">
        <div className="d-flex align-items-center results-btn">
          <button
            className="results-back"
            onClick={() => {
              if (pageType === "oneStage") dispatch(goBack());
              else dispatch(showResults(false));
            }}
          >
            <i className="fa fa-chevron-left" style={{ fontSize: "12px" }}></i>
            Back to search
          </button>
          <div>{section !== "Trends" && <LittleFilter />}</div>
        </div>
        <div className="d-flex align-items-center selection-btn">
          <span className="number-result-box-tag">Current Selection</span>{" "}
          &nbsp;
          <span className="number-result-box-results">
            {loadState.data === "fulfilled"
              ? numberWithCommas(filteredResults.length)
              : ""}
            &nbsp; results
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 order-1 order-lg-1 left-box-results">
          {filterBoxes.length > 1 ? (
            <SideFilter filterBoxes={filterBoxes} />
          ) : (
            <TrendsFilter />
          )}
        </div>
        <div
          className="col-lg-9  order-2 order-lg-2 mb-5 mb-lg-0 right-box-results"
          style={{ paddingLeft: "40px", paddingTop: "20px" }}
        >
          <div className="row">
            {loadState.data === "fulfilled" ? (
              filteredResults?.length === 0 ? (
                <h1>No results found</h1>
              ) : (
                filteredResults?.map((result, i) =>
                  useImageCard ? (
                    <CardImage
                      item={result}
                      key={`${result.id}-${result.data_type}`}
                    />
                  ) : (
                    <Card
                      item={result}
                      key={`${result.id}-${result.data_type}`}
                    />
                  )
                )
              )
            ) : (
              <>
                <div className="box-results-loading-container">
                  <ReactLoading type="spin" color="#59b8ec" />
                </div>
              </>
            )}
          </div>
          <Button
            variant="primary"
            size="sm"
            className="back-to-top"
            onClick={moveTopSearch}
          >
            Back To Top
          </Button>
        </div>
      </div>
    </div>
  );
}
