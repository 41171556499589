import "./App.css";

import Navbar from "./component/Navbar";
import Searchbar from "./component/searchbar/searchbar";

import { selectDisplayComponent } from "./Redux/slices/navigationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { initStore } from "./Redux/slices/azelis/azelisSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initStore());
    if (document.getElementById("boxed-wrapper")) {
      document.getElementById("boxed-wrapper").style.overflow = "visible";
    }
  }, [dispatch]);

  const DisplayComponent = useSelector(selectDisplayComponent);

  return (
    <div className="main">
      <Searchbar />
      <Navbar />
      <DisplayComponent />
    </div>
  );
}

export default App;
