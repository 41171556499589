import React, { useMemo } from "react";

import { Accordion } from "../../util/Accordion";
import FilterItems from "./FilterItems";

export const ParentItems = ({ filterBoxes }) => {
  const accordionItems = useMemo(() => {
    if (!filterBoxes.length) return [];

    return filterBoxes
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .map((el) => ({
        key: el.name,
        header: `${el.name} (${el.terms.length})`,
        body: (
          <div>
            <div key={el.parent}>
              <FilterItems filterBoxes={el.mod_terms} />
            </div>
          </div>
        ),
        expandable: true,
      }));
  }, [filterBoxes]);

  if (!accordionItems.length) return null;

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Accordion items={accordionItems} />
    </div>
  );
};

export default ParentItems;
