import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import Fuse from "fuse.js";

import Api from "../../api";
import { doSearch, resetFilter } from "../../globalActions";

export const initStore = createAsyncThunk(
  "/azelis/initStoreStatus",
  async () => {
    const api = new Api();
    const res = await api.getProfiles();

    return res.data;
  }
);

export const loadItems = createAsyncThunk(
  "/azelis/loadItemsStatus",
  async (_args, thunkAPI) => {
    const api = new Api();
    const slug = thunkAPI.getState().navigation.page;

    const res = await api.getItems(slug);

    return {
      data: res.data,
      slug,
    };
  }
);

export const loadFilterData = createAsyncThunk(
  "/azelis/loadFilterDataStatus",
  async (_args, thunkAPI) => {
    const api = new Api();
    const slug = thunkAPI.getState().navigation.page;

    const res = await api.initFilters(slug);

    return {
      data: res.data,
      slug,
    };
  }
);

const resetFilterBase = (state) => {
  for (const dataSet of Object.values(state.categories)) {
    for (const filter in dataSet.filters) {
      dataSet.filters[filter] = [];
    }
  }

  state.search = "";
};

const azelisSlice = createSlice({
  name: "azelis",
  initialState: {
    categories: {},
    search: "",
    selectedCheckboxes: {},
  },
  reducers: {
    toggleFilter: (state, action) => {
      const slugs = Object.keys(state.categories);
      const { taxonomy, termSlug } = action.payload;

      for (const slug of slugs) {
        const slugFilters = state.categories[slug].filters;
        if (!slugFilters.hasOwnProperty(taxonomy)) {
          slugFilters[taxonomy] = [];
        }

        const filterList = slugFilters[taxonomy];
        const filterIndex = filterList.findIndex(
          (filter) => filter === termSlug
        );

        if (filterIndex !== -1) filterList.splice(filterIndex, 1);
        else filterList.push(action.payload.termSlug);
      }
    },
    setFilterExclusive: (state, action) => {
      if (
        state.categories[action.payload.slug].filters.hasOwnProperty(
          action.payload.taxonomy
        ) &&
        state.categories[action.payload.slug].filters[
          action.payload.taxonomy
        ].includes(action.payload.termSlug)
      ) {
        return;
      }
      for (const filterKey of Object.keys(
        state.categories[action.payload.slug].filters
      )) {
        delete state.categories[action.payload.slug].filters[filterKey];
      }
      state.categories[action.payload.slug].filters[action.payload.taxonomy] = [
        action.payload.termSlug,
      ];
    },
    setLoad: (state, action) => {
      if (!["formulations", "product"].includes(action.payload.slug)) return;
      state[action.payload.slug].loading = action.payload.loading;
    },
    setSearchBox: (state, action) => {
      state.search = action.payload;
    },
    addSelectedCheckboxes: (state, action) => {
      const { taxonomy, termSlug } = action.payload;

      let orgChecked = state.selectedCheckboxes;
      let temp = orgChecked[taxonomy] ? orgChecked[taxonomy] : [];

      if (temp.includes(termSlug)) {
        temp.splice(temp.indexOf(termSlug), 1);
      } else {
        temp.push(termSlug);
      }
      orgChecked[taxonomy] = temp;

      state.selectedCheckboxes = {
        ...orgChecked,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadItems.fulfilled, (state, action) => {
      const { data, slug } = action.payload;
      for (const item of data) {
        const internalItemIndex = state.categories[slug].data.findIndex(
          (internalItem) => internalItem.id === item.id
        );

        if (internalItemIndex === -1) state.categories[slug].data.push(item);
        else
          state.categories[slug].data.splice(internalItemIndex, 1, {
            ...state.categories[slug].data[internalItemIndex],
            ...item,
          });
      }
      state.categories[slug].loading.data = "fulfilled";
    });
    // builder.addCase(goTo, resetFilterBase)
    builder.addCase(resetFilter, resetFilterBase);
    builder.addCase(doSearch, (state, action) => {
      state.search = action.payload;
    });
    builder.addCase(loadFilterData.fulfilled, (state, action) => {
      const { slug, data } = action.payload;
      state.categories[slug].data = data;
      state.categories[slug].loading.filters = "loaded";
    });
    builder.addCase(initStore.fulfilled, (state, action) => {
      for (const [label, slug] of Object.entries(action.payload.post_types)) {
        // temp solution to remove supplier from ingredient
        let taxonomies = [];
        if (slug === "product") {
          const ingredientTaxonomies = action.payload.taxonomies["product"];
          taxonomies = ingredientTaxonomies.filter(
            (item) => item.name !== "supplier"
          );
        } else {
          taxonomies = action.payload.taxonomies[slug];
        }

        state.categories[slug] = {
          label,
          data: [],
          filters: {},
          taxonomies: [...new Set(taxonomies)],
          displayType: ["oneStage", "twoStage"].includes(
            action?.payload?.displayType?.[slug] || ""
          )
            ? action.payload.displayType[slug]
            : action.payload.taxonomies[slug].length === 1
            ? "oneStage"
            : "twoStage",
          loading: {
            filters: null,
            data: null,
          },
        };
      }
    });
  },
});

export default azelisSlice.reducer;
export const {
  setSearchBox,
  setLoad,
  toggleFilter,
  setFilterExclusive,
  addSelectedCheckboxes,
} = azelisSlice.actions;

export const selectTabs = (state) => {
  return Object.entries(state.azelis.categories).map(([slug, slugData]) => ({
    slug,
    label: slugData.label,
  }));
};

export const selectCheckedFilterItems = (state) => {
  return state.azelis.selectedCheckboxes;
};

export const selectTaxonomies = (state) => {
  const slug = state.navigation.page;
  if (!state.azelis.categories.hasOwnProperty(slug)) return [];
  return state.azelis.categories[slug]?.taxonomies || [];
};

export const selectPageType = (state) => {
  const slug = state.navigation.page;
  if (!slug) return null;

  return state.azelis.categories[slug].displayType;
};
export const selectResults = (state) => {
  const slug = state.navigation.page;
  if (!slug) return [];

  return state.azelis.categories[slug].data;
};
export const selectFilters = (state) => {
  const slug = state.navigation.page;
  if (!slug) return {};

  return state.azelis.categories[slug].filters;
};
export const selectSearch = (state) => state.azelis.search;
export const selectLoading = (state) => {
  const slug = state.navigation.page;
  if (!slug)
    return {
      filters: "pending",
      data: "pending",
    };

  return state.azelis.categories[slug].loading;
};

export const selectFilteredResults = (state) => {
  const slug = state.navigation.page;
  const slugData = state.azelis.categories[slug].data;

  const validatedFilters = Object.entries(
    state.azelis.categories[slug].filters
  ).map(([taxonomy, filterArray]) => [
    taxonomy,
    filterArray.filter((filter) => filterIsValid(slugData, taxonomy, filter)),
  ]);

  const baseFiltered = slugData.filter((item) =>
    validatedFilters.every(
      ([taxonomy, filterArray]) =>
        filterArray.some((filter) =>
          item.tags.some(
            (tag) => tag.taxonomy_label === taxonomy && tag.slug === filter
          )
        ) || !filterArray.length
    )
  );

  if (!(state.navigation.isSearching && state.navigation.atResults))
    return baseFiltered;
  if (!state.azelis.search.length) return baseFiltered;

  const searchQuery = state.azelis.search;
  const searchKeys = [
    {
      name: "title",
      weight: 0.6,
    },
    {
      name: 'inci',
      weight: 0.6,
    },
    {
      name: "body",
      weight: 0.4,
    },
  ];

  const searchOptions = {
    keys: searchKeys,
    threshold: 0.4,
  };

  const searchInstance = new Fuse(baseFiltered, searchOptions);
  const searchResults = searchInstance.search(searchQuery);

  return searchResults.map((result) => result.item);
};

const selectBoxesFunction = (taxonomies, unfilteredResults, filters) => {
  if (!taxonomies.length || !unfilteredResults?.length) return [];

  const output = taxonomies.reduce((boxes, box) => {
    boxes[box.label] = {
      taxonomy: box.name,
      name: box.label,
      terms: [],

      mod_terms: [],
    };
    return boxes;
  }, {});

  const taxonomyLabels = taxonomies.map(
    (dynamicTaxonomy) => dynamicTaxonomy.label
  );

  for (const taxonomy of taxonomyLabels) {
    const itemFilters = filters.hasOwnProperty(taxonomy)
      ? filters[taxonomy]
      : [];

    const otherFilters = Object.entries(filters)
      .filter(([filterTaxonomy]) => filterTaxonomy !== taxonomy)
      .map(([filterTaxonomy, filterList]) => [
        filterTaxonomy,
        filterList.filter((filter) =>
          filterIsValid(unfilteredResults, filterTaxonomy, filter)
        ),
      ]);
    const filteredItems = unfilteredResults.filter((item) =>
      otherFilters.every(
        ([filterTaxonomy, filterArray]) =>
          !filterArray.length ||
          filterArray.every((filter) =>
            item.tags
              ? item.tags.some(
                  (tag) =>
                    tag.slug === filter && tag.taxonomy_label === filterTaxonomy
                )
              : false
          )
      )
    );

    const tagSlugs = new Set();

    for (const item of filteredItems) {
      for (const tag of item?.tags || []) {
        if (tag.taxonomy_label !== taxonomy) continue;
        if (!tag.name || !tag.slug) continue;
        if (tagSlugs.has(tag.slug)) {
          continue;
        }

        tagSlugs.add(tag.slug);

        const count = filteredItems.filter((item) =>
          item.tags.some(
            (itemTag) =>
              itemTag.slug === tag.slug &&
              itemTag.taxonomy_label === tag.taxonomy_label
          )
        ).length;

        let termsSlug = output[taxonomy].terms.map((term) => term.slug);

        /**
         * Refactor terms based on parent.
         */
        let termParents = output[taxonomy].mod_terms.map((item) => item.parent);
        const tempParent = tag.parent.replace("&amp;", "&");
        if (!termParents.includes(tempParent)) {
          output[taxonomy].mod_terms.push({
            parent: tempParent,
            items: [],
            count: 1,
          });
          let parentItemLen = termParents.length;
          output[taxonomy].mod_terms[parentItemLen].items.push({
            name: tag.name.replace("&amp;", "&"),
            slug: tag.slug,
            count,
            checked: itemFilters.includes(tag.slug),
            taxonomy: taxonomy,
            parent: tempParent,
          });
        } else {
          let index = termParents.indexOf(tempParent);
          let itemsInParent = output[taxonomy].mod_terms[index].items.map(
            (item) => item.slug
          );
          if (!itemsInParent.includes(tag.slug)) {
            output[taxonomy].mod_terms[index].items.push({
              name: tag.name.replace("&amp;", "&"),
              slug: tag.slug,
              count,
              checked: itemFilters.includes(tag.slug),
              taxonomy: taxonomy,
              parent: tempParent,
            });
            output[taxonomy].mod_terms[index].count++;
          }
        }

        if (!termsSlug.includes(tag.slug)) {
          output[taxonomy].terms.push({
            name: tag.name.replace("&amp;", "&"),
            slug: tag.slug,
            count,
            checked: itemFilters.includes(tag.slug),
            taxonomy: taxonomy,
            parent: tempParent,
          });
        }
      }
    }
  }

  const sortedResults = Object.values(output).map((box) => {
    const boxCopy = { ...box };
    boxCopy.terms.sort((a, b) => a.name.localeCompare(b.name));
    return boxCopy;
  });

  return sortedResults;
};

export const selectAllTerms = (state) => {
  const slug = state.navigation.page;
  if (!state.azelis.categories.hasOwnProperty(slug)) return [];

  const output = [];
  for (const item of state.azelis.categories[slug].data) {
    for (const tag of item.tags) {
      if (
        !output.some(
          (outputTag) =>
            outputTag.slug === tag.slug &&
            outputTag.taxonomy_label === tag.taxonomy_label
        )
      ) {
        output.push(tag);
      }
    }
  }

  return output;
};
export const selectBoxes = createSelector(
  [selectTaxonomies, selectResults, selectFilters],
  selectBoxesFunction
);

function filterIsValid(unfilteredResults, filterTaxonomy, filterSlug) {
  return unfilteredResults.some((item) =>
    item.tags.some(
      (tag) => tag.taxonomy_label === filterTaxonomy && tag.slug === filterSlug
    )
  );
}
