import React, { useMemo } from "react";

import { Accordion } from "../../util/Accordion";
import { CheckLine } from "./../../boxtype/boxtype";

import "./checkbox.css";

const FilterItems = ({ filterBoxes }) => {
  const accordionItems = useMemo(() => {
    if (!filterBoxes.length) return [];

    const termToCheckLine = (term) => (
      <CheckLine
        key={term.slug}
        style={{ padding: "0 1.25rem" }}
        slug={term.slug}
        taxonomy={term.taxonomy}
        checked={term.checked}
        name={term.name}
        count={term.count}
      />
    );

    return (
      filterBoxes
        // .filter((el) => el.count > 1)
        .sort((a, b) =>
          a.parent.toLowerCase() > b.parent.toLowerCase() ? 1 : -1
        )
        .map((filterBox) => {
          const checked = filterBox.items.filter((term) => term.checked);
          const unchecked = filterBox.items.filter((term) => !term.checked);

          return {
            key: filterBox.parent,
            header: `${filterBox.parent} (${filterBox.count})`,
            body: (
              <div style={{ paddingBottom: unchecked.length ? "30px" : "0px" }}>
                {unchecked
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  )
                  .map(termToCheckLine)}
              </div>
            ),
            alwaysBody: (
              <div style={{ paddingBottom: checked.length ? "30px" : "0px" }}>
                {checked
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
                  )
                  .map(termToCheckLine)}
              </div>
            ),
            expandable: Boolean(unchecked.length),
          };
        })
    );
  }, [filterBoxes]);

  // const ItemsWithoutParent = useMemo(() => {
  //   if (!filterBoxes.length) return [];

  //   const termToCheckLine = (term) => (
  //     <CheckLine
  //       key={term.slug}
  //       style={{ padding: "0 1.25rem" }}
  //       slug={term.slug}
  //       taxonomy={term.taxonomy}
  //       checked={term.checked}
  //       name={term.name}
  //       count={term.count}
  //     />
  //   );

  //   return filterBoxes
  //     .sort((a, b) =>
  //       a.parent.toLowerCase() > b.parent.toLowerCase() ? 1 : -1
  //     )
  //     .filter((el) => el.count === 1)
  //     .map((el) => {
  //       const checked = el.items.filter((term) => term.checked);
  //       const unchecked = el.items.filter((term) => !term.checked);

  //       return (
  //         <div style={{ marginBottom: "20px" }}>
  //           <div>
  //             {checked
  //               .sort((a, b) =>
  //                 a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  //               )
  //               .map(termToCheckLine)}
  //           </div>
  //           <div>
  //             {unchecked
  //               .sort((a, b) =>
  //                 a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  //               )
  //               .map(termToCheckLine)}
  //           </div>
  //         </div>
  //       );
  //     });
  // }, [filterBoxes]);

  return (
    <div style={{ marginLeft: "30px" }}>
      {/* {ItemsWithoutParent} */}
      <Accordion items={accordionItems} />
    </div>
  );
};

export default FilterItems;
