import { useDispatch } from "react-redux";
import { resetFilter } from "../../../Redux/globalActions";
import "./filter.css";

export default function Filter() {
  const dispatch = useDispatch();

  return (
    <div className="little-filter-main">
      <div
        onClick={() => {
          dispatch(resetFilter());
        }}
        className="filter-reset-button"
      >
        Reset
      </div>
    </div>
  );
}
