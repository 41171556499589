import { makeShorter } from "../../utils/function";

import "./cardimage.css";

export default function CardImage(e) {
  return (
    <div className="col-md-4 col-sm-12" style={{ marginBottom: "30px" }}>
      <a
        href={e.item.url}
        target="_blank"
        rel="noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div
          className="main-card"
          style={{ height: "271px", marginRight: "5px" }}
        >
          {e.item.thumbnail && (
            <div
              className="card-image"
              style={{ backgroundImage: "url(" + e.item.thumbnail + ")" }}
            ></div>
          )}
          <div className="card-content">
            <div className="card-title">{e.item.title}</div>
            <div className="card-inci">
              {e.item.inci && makeShorter(e.item.inci, 60)}
            </div>
            <div className="card-des">{makeShorter(e.item.body, 150)}</div>
          </div>
        </div>
      </a>
    </div>
  );
}
